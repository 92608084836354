// Card
$card-box-shadow-color: rgba(58, 59, 69, 0.15);

//Header
$header-background-color: #ffffff;
$header-navlink-text-color: #5865f2;
$languaje-dropdown: #5865f2;
$link-component: #f2f2f2;

// Forms
// Inputs
$imput-bg-color: #ffffff;
$input-component-error-text-color: #ff0000;

//Sidebar

$sidebar-bg-color: #111827;
$sidebar-font-color: #ffffff;

// Navbar
$navbar-block-area-bg-color: rgba(52, 69, 84, 0.6);
$navbar-x-icon: #53b0e1;
$navbar-menu-bg-color: #ffffff;
$navbar-border-color: #a8b5da;
$navbar-text-color: #f5f5f5;
$navbar-text-hover-color: #1a83ff;
$navbar-hr-color: #f2f3f4;
$navbar-item-bg-color: #f6f6f6;
$navbar-item-text-color: gray;

// Button
$button-border-color: #f27a36;
$button-bg-color: #29acfc;
$button-text-color: #ffffff;
$action-button-bg-color: #f27730;
$action-text-bg-color: #ffffff;

//Footer
$footer-background-color: rgba(35, 39, 42, 0.95);
$footer-title-color: #29acfc;
$footer-link-color: #ffffff;
$footer-socialnetwork-color: #ffffff;
$footer-hr-color: #29acfc;
