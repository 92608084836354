.automovilPregunta {
  max-width: 600px;
  margin: 24px 0px 0px 0px;
}

.flechaPregunta {
  max-width: 60px;
  margin: 0px 0px 12px 0px;
}

.header-component-retail {
  padding: 12px 12px;
  margin-bottom: 12px;
}

.retail-plan {
  height: 180px !important;
  width: 200px !important;
  margin: 0px 0px 12px 0px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  img {
    height: 70px;
  }
}

.membership-info-component {
  width: 400px !important;
  text-align: center;
  position: relative;
  padding-top: 60px !important;

  .yellow-tag {
    height: 80px;
    position: absolute;
    top: 10px;
  }

  .membership-info-price {
    position: absolute;
    top: 16px;
    font-size: 26px;
    font-weight: bold;
  }

  .membership-info-title-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    height: 120px;
    width: 360px;
    img {
      // width: 300px;
      height: 80px;
    }
  }

  .membership-info-text {
    margin: 12px 0px 24px 0px;
  }
}

.membership-info-container {
  display: flex;
  border-radius: 24px;
  flex-flow: column;
  align-items: center;
  border: 1px solid #d9d9d9;
  background-color: #fcfcfc;
  padding: 24px;
  margin: 24px 12px;
  width: 300px;
  cursor: pointer;
  transition: all 300ms;
}

.membership-info-container-selected {
  box-shadow: 0px 0px 20px #f27730;
  width: 700px !important;
  background-color: #fff;
}

.membership-info-component:hover {
  transform: scale(1, 1) !important;
}

.membership-info-color-classic,
.membership-info-color-camionChasisCortoClassic,
.membership-info-color-camionChasisLargoClassic {
  background: rgb(20, 144, 234);
  background: linear-gradient(
    90deg,
    rgba(20, 144, 234, 1) 0%,
    rgba(61, 92, 169, 1) 50%,
    rgba(45, 58, 151, 1) 100%
  );
}

.membership-info-color-plus,
.membership-info-color-camionChasisCortoPlus,
.membership-info-color-camionChasisLargoPlus {
  background: rgb(245, 153, 100);
  background: -moz-linear-gradient(
    90deg,
    rgba(245, 153, 100, 1) 0%,
    rgba(230, 96, 40, 1) 50%,
    rgba(220, 75, 33, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(245, 153, 100, 1) 0%,
    rgba(230, 96, 40, 1) 50%,
    rgba(220, 75, 33, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(245, 153, 100, 1) 0%,
    rgba(230, 96, 40, 1) 50%,
    rgba(220, 75, 33, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f59964",endColorstr="#dc4b21",GradientType=1);
}

.membership-info-color-motorider {
  background: rgb(40, 44, 48);
  background: -moz-linear-gradient(
    90deg,
    rgba(40, 44, 48, 1) 0%,
    rgba(211, 210, 210, 1) 50%,
    rgba(47, 51, 54, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(40, 44, 48, 1) 0%,
    rgba(211, 210, 210, 1) 50%,
    rgba(47, 51, 54, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(40, 44, 48, 1) 0%,
    rgba(211, 210, 210, 1) 50%,
    rgba(47, 51, 54, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#282c30",endColorstr="#2f3336",GradientType=1);
}

.membership-info-color-retro {
  background: rgb(140, 78, 36);
  background: -moz-linear-gradient(
    90deg,
    rgba(140, 78, 36, 1) 0%,
    rgba(186, 121, 75, 1) 50%,
    rgba(229, 161, 112, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(140, 78, 36, 1) 0%,
    rgba(186, 121, 75, 1) 50%,
    rgba(229, 161, 112, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(140, 78, 36, 1) 0%,
    rgba(186, 121, 75, 1) 50%,
    rgba(229, 161, 112, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8c4e24",endColorstr="#e5a170",GradientType=1);
}

.membership-info-color-gold,
.membership-info-color-camionChasisCortoGold,
.membership-info-color-camionChasisLargoGold {
  background-color: #21272c !important;
}

.membership-info-color-dorado {
  background: rgb(177, 122, 46);
  background: -moz-linear-gradient(
    90deg,
    rgba(177, 122, 46, 1) 0%,
    rgba(250, 242, 197, 1) 50%,
    rgba(174, 118, 42, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(177, 122, 46, 1) 0%,
    rgba(250, 242, 197, 1) 50%,
    rgba(174, 118, 42, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(177, 122, 46, 1) 0%,
    rgba(250, 242, 197, 1) 50%,
    rgba(174, 118, 42, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b17a2e",endColorstr="#ae762a",GradientType=1);
}

.retail-membership-info-logo {
  width: 70px;
  height: 70px;
  margin: 12px;
  border-radius: 100000px;
  position: absolute;
  top: -17px;
  left: -12px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  img {
    max-width: 70% !important;
    max-height: 70% !important;
  }
}

.retail-membership-info-service {
  position: relative;
  width: 300px;
  background-color: #eaeaea;
  border-radius: 10000px;
  margin: 12px;
  height: 60px;
  padding: 12px 0px 0px 48px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  p {
    width: 150px !important;
    // border: 1px solid black;
  }
}

@media (max-width: 700px) {
  .automovilPregunta {
    width: 80%;
  }

  .membership-info-component:hover {
    width: 80%;
  }

  .header-component-retail {
    display: flex;
    align-items: center !important;
    justify-content: center;
    margin-right: 0px;
  }

  .retail-plan,
  .retail-plan-container {
    margin: 0px !important;
    width: 120px !important;
    img {
      height: 50px;
    }
    .row {
      margin: 0px !important;
    }
  }

  .retail-plan {
    height: 120px !important;
  }

  .retail-landing-container {
    margin: 0px;
    padding: 0px !important;

    .membership-buttons-container {
      padding: 0px !important;
      // width: 100% !important;
    }
  }

  .checkbox-button {
    margin-right: 0px;
  }
}
