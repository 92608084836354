.file-dropzone-component {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  h6,
  p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  text-align: center;
  div {
    border: 2px solid #c6c6c6;
    border-radius: 12px;
    background-color: rgba(236, 236, 236, 0.5);
    width: 400px;
    max-width: 100%;
    height: 80px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 12px 0px;
    cursor: pointer;
    transition: all 300ms;
    img {
      height: 18px;
    }
    p {
      font-size: 14px;
      margin: 8px 0px 0px 0px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .wizzard-logo-button {
    height: 42px;
    width: 42px;
  }

  .file-dropzone-component {
    div {
      width: 250px;
      border: 1px solid black;
    }
  }
}

.file-dropzone-component div:hover {
  background-color: rgba(236, 236, 236, 1);
}

.file-component {
  border: 2px solid #c6c6c6;
  border-radius: 12px;
  background-color: rgba(236, 236, 236, 0.5);
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0px;
  padding: 4px 24px;
  // width: 400px;
  cursor: pointer;
  transition: all 300ms;
  div {
    display: flex;
    flex-flow: row;
    align-items: center;
    h6 {
      margin: 0px 24px;
    }
  }

  .file {
    height: 36px;
  }

  .file-close {
    height: 36px;
    cursor: pointer;
  }
}

.dropzone-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 700px;
}

.dropzone-label {
  font-weight: 500;
  margin: 4px 0px;
  height: 24px;
  cursor: pointer;
}

.sales-dropzone-true {
  border: 3px solid red !important;
  background-color: rgba(255, 0, 0, 0.1) !important;
}

.export-breadcrumb-options {
  border-bottom: 1px solid #67606039;
}

.export-breadcrumb-options:hover {
  background-color: #978f8f39;
}
