.modal-header {
  height: 60px;
  border: none !important;
}

.modal-body {
  display: flex;
  flex-flow: column;
  align-items: center;
  border: none;
  form {
    max-width: 90%;
  }
}

.modal-footer {
  border: none !important;
}

.modal-messages-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-evenly;
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1440px !important;
  }
}

.modal-message-container {
  border: 2px solid #a4a4a4;
  margin: 24px 0px;
  padding: 24px 12px;
  border-radius: 12px;
  width: 300px;
  min-height: 480px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 11px;
    width: 100%;
  }
}

.google-maps-container {
  width: 100%;
  height: 100%;
}

.change-case-stage-modal {
  text-align: center;
  b {
    margin: 0px 4px;
  }
}
