.order-card {
  margin: 24px 0px;
  min-height: 64px;
  padding: 24px 24px;
  border-radius: 8px;
  border: 1px solid #cacaca;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: white;
  h5 {
    margin: 0px;
  }
}

.arrow-down {
  cursor: pointer;
  transition: all 300ms;
}

.arrow-down:hover {
  transform: scale(0.9, 0.9);
}

.parameter-logo-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 50px;
}
