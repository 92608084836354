.vehicle-parameter-class-container {
  width: fit-content;
}

.vehicle-parameter-class-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    margin: 0px 6px;
    cursor: pointer;
  }
}
