.plan-card-status {
  padding: 4px 16px;
  border-radius: 8px;
  color: white;
  font-weight: 500;
}

.plan-card-status-true {
  background-color: #2cbf8a;
}

.plan-card-status-false {
  background-color: #ff3519;
}

.case-membership-logo {
  height: 50px;
  margin-right: 6px;
}

.indicator-component-header {
  display: flex;
  flex-flow: row !important;
  align-items: center;
  justify-content: space-between;
  border-top: 4px solid #c6c6c6;
  h6 {
    font-weight: 500;
    font-size: 16px;
    margin: 0px;
  }
}

.facturacion-component-quantity {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #c6c6c6;
  width: 30px;
  height: 30px;
  border-radius: 20000px;
  span {
    font-weight: 500;
    color: gray;
  }
}

.incidence-component-header {
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  h6 {
    margin: 0px;
    color: #a4a4a4;
    font-weight: 200;
    font-size: 14px;
  }
  img {
    margin: 0px 12px 0px 20px;
    cursor: pointer;
  }
}

.incidence-card-creation-date {
  font-size: 14px;
}

.incidence-component-code-header {
  display: flex;
  flex-flow: row;
  position: relative;
  img {
    padding: 0px 6px 0px 12px;
    margin: 0px;
  }
}

.incidence-status-options-container {
  width: 200px;
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 24px;
  right: 8px;
  z-index: 20000;
  background-color: white;
  box-shadow: 1px 4px 12px #d3d4d5;
  border-radius: 8px;
}

.incidence-status-options-option {
  padding: 6px 12px;
  border-top: 1px solid gainsboro;
  cursor: pointer;
  transition: all 300ms;
}

.incidence-status-options-option:hover {
  background-color: #ebeced;
}

.incidence-component-vehicle {
  margin: 0px;
  color: #a4a4a4;
  font-weight: 200;
  font-size: 14px;
}

.incidence-card {
  padding: 48px 24px !important;
}

.incidence-card-reminder {
  border: 4px solid #d6c635 !important;
  background-color: rgba(214, 198, 53, 0.125) !important;
}
.incidence-card-warning {
  border: 4px solid #c43d3d !important;
  background-color: rgba(196, 61, 61, 0.0125) !important;
}

.incidence-card-text {
  p {
    margin: 0px 0px 12px 0px;
    font-weight: 400;
    font-size: 12px;
  }
}

.incidence-comment-logo {
  margin-right: 12px;
}

.gasLogo {
  transition: all 300ms;
  height: 42px;
  cursor: pointer;
}

.gasLogo:hover {
  opacity: 0.5;
}

.incidence-comment-counter-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  p {
    margin-right: 24px;
  }
}

.indicators-card {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 12px 24px !important;
  height: 250px !important;
  h4 {
    width: 250px;
    text-align: center;
    margin-top: 12px;
  }
  h3 {
    font-size: 36px;
    margin-bottom: 0px;
  }
}

.operations-stat-card {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
      height: 24px;
    }
    h5,
    h6,
    h4 {
      margin: 2px 4px;
      font-size: 16px;
      text-align: center;
    }
  }
}

.sales-card {
  cursor: pointer;
  padding: 18px 24px !important;
  transition: all 300ms;
  h3 {
    margin-bottom: 0px;
  }
  img {
    height: 32px;
  }
}

.sales-card:hover {
  transform: scale(0.98, 0.98);
}

.analytics-card-image-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.icon-service-code-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px !important;
  p {
    margin: 0px;
  }
}

.detail-order-card {
  padding: 24px !important;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.order-text-separation {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
}
