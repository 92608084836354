@import '../../../../variables/styles.scss';

.form-input-title {
  margin: 0px 0px 12px 0px !important;
}

.react-datepicker-popper {
  z-index: 2;
}

.input-component-container {
  display: flex;
  flex-flow: column;
  margin: 4px 0px;

  label {
    font-weight: 500;
    margin: 4px 0px;
    height: 24px;
    cursor: pointer;
  }

  textarea {
    min-height: 100px !important;
  }

  .error-input {
    border: 3px solid $input-component-error-text-color;
  }

  .checkbox-input {
    width: 18px !important;
    height: 18px;
  }

  .checkbox-input-sublabel-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    input {
      margin-right: 12px;
    }
    h6 {
      margin: 0px !important;
    }
  }

  input,
  select,
  textarea {
    width: 100%;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    background-color: $imput-bg-color;
    padding: 5px 10px;
  }
  input:hover {
    box-shadow: 0px 0px 2px gray;
  }

  *:disabled {
    background-color: #f2f2f2;
  }

  p {
    color: $input-component-error-text-color;
    font-size: 12px;
  }
  div {
    position: relative;
    // width: 100%;
    color: #2f4f4f;
    ion-icon {
      font-size: 24px;
      position: absolute;
      right: 12px;
      top: 6px;
      cursor: pointer;
    }
  }
}

.checkbox-input-container {
  display: flex;
  flex-flow: column;
  // align-items: center;
  justify-content: center;
}

.input-sublabel-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  h6 {
    width: fit-content;
    min-width: 75%;
    margin: 0px;
  }
  input {
    width: 100% !important;
  }
}

.simple-select {
  margin: 0px 6px;
  display: flex;
  flex-flow: column;
  label {
    margin-right: 12px;
  }
  select {
    border: 1px solid #cacaca;
    //   color: #cacaca;
    border-radius: 8px;
    padding: 4px 12px;
    cursor: pointer;
  }
}

.select-per-page {
  margin: 0px 6px;
  label {
    margin-right: 12px;
  }
  select {
    border: 1px solid #cacaca;
    //   color: #cacaca;
    border-radius: 8px;
    padding: 4px 12px;
    cursor: pointer;
  }
}

.incidence-search {
  display: flex;
  flex-flow: row;
  position: relative;
  .searchImageContainers {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 36px;
    border: 1px solid #cacaca;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;
    transition: all 300ms;
    cursor: pointer;
  }

  .searchImageContainers:hover {
    background-color: rgba(202, 202, 202, 0.5);
  }

  input {
    background-color: #f7f8f9;
    border: 1px solid #cbcbcb;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    min-width: 240px;
    padding: 0px 12px;
  }
  .incidence-search-options-container {
    position: absolute;
    top: 36px;
    left: 0px;
    width: 100%;
    border: 1px solid #cacaca;
    display: flex;
    flex-flow: column;
    background-color: #f7f8f9;
    z-index: 2;
    border-radius: 8px;
    div {
      // width: 100%;
      cursor: pointer;
      transition: all 300ms;
      padding: 6px 12px;
      border-top: 1px solid gray;
      p {
        margin: 0px;
      }
    }
    div:hover {
      opacity: 0.5;
      padding-left: 24px;
    }
  }
}

.copy-payment-data-image-button-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
  cursor: pointer;
  h6 {
    font-size: 12px;
    margin: 4px;
  }
  img {
    height: 24px;
    transition: all 300ms;
  }
}

.copy-payment-data-image-button-container:hover {
  opacity: 0.7;
}

.checkbox-component-container {
  padding: 0px !important;
  display: flex;
  flex-flow: row;
  img {
    height: 20px;
    margin-left: 12px;
  }
}

.monto-con-igtf {
  border: 2px solid orangered;
  background-color: #f3f3f3;
  border-radius: 8px;
  margin: 12px;
  padding: 6px;
  text-align: center;
}

.advisor-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.assing-myself-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f27730;
  padding-right: 45px;
  padding-left: 45px;
  border: 1px solid #ffffff;
  margin: 0 auto;
  vertical-align: middle;
  border-radius: 50px;
}

.assing-myself-button:hover {
  background-color: #ffffff;
  border: 1px solid #f27730;
  cursor: pointer;
}

.assing-myself-text {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  justify-self: center;
  vertical-align: middle;
  color: #000000;
}

.assing-advisor-select {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c6c6c6;
  margin: 0 auto;
  vertical-align: middle;
  border-radius: 50px;
  width: 100%;
  padding: 5px 8px;
  border: 1px solid #ced4da;
  font-size: 14px;
  color: #2f4f4f;
  // appearance: none; /* Para ocultar el icono de flecha predeterminado en algunos navegadores */
  // -webkit-appearance: none; /* Para ocultar el icono de flecha predeterminado en Safari y Chrome */
  // -moz-appearance: none;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f27730; /* Color naranja */
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 4px;
  }
}
.assing-advisor-select:hover {
  cursor: pointer;
}

.assing-campaing-select {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
  border: 1px solid #f27730;
  margin: 0 auto;
  vertical-align: middle;
  border-radius: 50px;
}

.assing-campaing-select:hover {
  cursor: pointer;
}

.select-multiple-with-search {
  position: relative;
  // width: 270px;

  .select-box {
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 4px;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pointer-icon {
      margin-left: 8px;
      width: 12px;
      height: 12px;
      transition: transform 0.3s ease;
    }

    .pointer-icon.open {
      transform: rotate(180deg);
    }
  }

  .dropdown-container {
    position: absolute;
    min-width: 250px;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .options-container-multiple {
    max-height: 200px;
    overflow-y: auto;
    padding-bottom: 0; /* Eliminar espacio para el botón de buscar */
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #f27730;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
    .option-multiple {
      display: flex;
      align-items: center;
      padding: 8px;
      input[type='checkbox'] {
        appearance: none;
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-right: 8px;
        cursor: pointer;
        position: relative;
        &:checked {
          background-color: #f27730;
          border-color: #f27730;
          &::after {
            color: white;
            font-size: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  .option-multiple:hover {
    background-color: #c6c6c6;
    input[type='checkbox'] {
      border: 1px solid #fff;
    }
  }

  .dropdown-container {
    position: absolute;
    z-index: 1000;
  }
}

.search-bar {
  width: 100%;
  padding: 4px;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 0px 2px gray;
  font-size: 12px;
  color: #2f4f4f;
}

.search-bar::placeholder {
  color: #ced4da;
}

.search-bar:focus {
  outline: none;
  border-color: #f27730;
  box-shadow: 0px 0px 5px #f27730;
}

// .buttons-container {
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
//   margin: 0;
//   padding: 0 12px;
//   background-color: #ffffff;
// }

.search-button,
.clear-filters-button {
  width: 48%;
  padding: 2px;
  background-color: #f27730;
  border-radius: 40px;
  font-size: 14px;
  color: #fff;
  border: none;
  cursor: pointer;
  border: 1px solid #f27730;
  &:disabled {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed;
  }
  // disbled and hover at the same time
  &:disabled:hover {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed;
  }
}

.search-button:hover,
.clear-filters-button:hover {
  background-color: #fff;
  color: #f27730;
  border: 1px solid #f27730;
}

.search-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.clear-filters-button {
  color: #fff; /* Color blanco para que coincida con el botón de buscar */
  text-decoration: none; /* Sin subrayado */
  background-color: #f27730; /* Fondo naranja */
  border: none; /* Sin borde */
  cursor: pointer; /* Cursor de mano */
}

.search-item-container {
  display: flex;
  flex-flow: row;
  height: 36px;
  width: 150px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: none;
  transition: all 300ms;
  background-color: #fff;
  cursor: pointer;

  select {
    border: none;
    background-color: #fff;
    cursor: pointer;
    font-size: 16px;
  }
}

.search-image-button {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 36px;
  border: 1px solid #cacaca;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: all 300ms;
  border-left: none;
  padding: 8px;
  cursor: pointer;
}

.membership-search-input {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  border-radius: 0;
  border: 1px solid #ccc;
  padding: 0px 12px;
  background-color: #fff;
  cursor: pointer;
}

.custom-datepicker {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 0px 2px gray;
  font-size: 14px;
  color: #2f4f4f;
}

.custom-calendar {
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.custom-calendar .react-datepicker__header {
  background-color: #f27730;
  border-bottom: 1px solid #ced4da;
}

.custom-calendar .react-datepicker__current-month,
.custom-calendar .react-datepicker__day-name,
.custom-calendar .react-datepicker__day {
  color: #fff;
}

.custom-calendar .react-datepicker__day {
  color: #000;
}

.custom-calendar .react-datepicker__day--selected,
.custom-calendar .react-datepicker__day--in-range,
.custom-calendar .react-datepicker__day--keyboard-selected {
  background-color: #f27730;
  color: #fff;
}

.custom-calendar .react-datepicker__day--in-selecting-range {
  background-color: rgba(242, 119, 48, 0.5);
}
