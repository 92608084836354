@import "../../../variables/styles.scss";

.page-not-found-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  img {
    margin: 24px 0px;
  }
  h4 {
    margin: 12px 0px;
    font-size: 32px;
  }
  h5 {
    font-size: 24px;
    margin: 12px 0px;
  }
  img:last-child {
    opacity: 0.5;
    width: 200px;
  }
}
