.pac-container {
  //   display: block !important;
  z-index: 10000000;
}

.input-map-container {
  margin-top: 24px;
  display: flex;
  flex-flow: column;
  input {
    margin: 12px 0px;
  }
}

.input-map {
  // border: 1px solid black;
  margin: 12px;
  width: 100% !important;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  padding: 5px 10px;
}

.markpoint-legend-conteiner {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  div {
    width: 100%;
  }
}

.markpoint-legend {
  background-color: red;
  width: 35px !important;
  height: 35px !important;
  border-radius: 100000px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin-right: 12px;
  h6 {
    margin: 0px 0px 4px 0px;
    font-weight: lighter;
    font-size: 24px;
  }
}
