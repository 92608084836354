.membership-buttons-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
}

.plan-button-container {
  display: flex;
  border-radius: 24px;
  flex-flow: column;
  align-items: center;
  border: 1px solid #d9d9d9;
  background-color: #fcfcfc;
  padding: 24px;
  margin: 24px 12px;
  width: 300px;
  cursor: pointer;
  transition: all 300ms;
}

.plan-button-container-selected {
  box-shadow: 0px 0px 20px #f27730;
  background-color: #fff;
}

.noPlanAlert-true {
  border: 2px solid red !important;
  background-color: rgba(255, 0, 0, 0.1) !important;
}

.plan-button-container:hover {
  transform: scale(0.95, 0.95);
}

.gifcard-message-component {
  margin: 24px 0px;
  border: 2px solid #2cbf8a;
  border-radius: 24px;
  padding: 12px 24px;
  min-width: 50%;
  display: flex;
  img {
    margin-right: 12px;
  }
}

.membership-button-logo {
  height: 120px;
}

.wizzard-container {
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.wizzard-row {
  background-color: transparent;
}

.wizzard-logo-button {
  height: 56px;
  margin: 24px 0px;
  cursor: pointer;
}

.wizzard-logo-button-uncompleted {
  opacity: 0.4;
  filter: grayscale(100%);
}

.wizzard-bar {
  width: 25%;
  height: 6px;
  background-color: #e66028;
}

.wizzard-bar-uncompleted {
  height: 4px;
  background-color: #d8d8d8;
}

.payment-method-button-logo {
  max-width: 100%;
}

.membership-button-container {
  height: 180px !important;
  display: flex !important;
  flex-flow: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.purchase-container {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-flow: column;
}

.purchase-section-component {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #eaeaea;
  h6 {
    margin: 12px;
  }
}

.recharge-text {
  font-size: 11px;
  color: gray;
  text-align: center;
}

.membership-button-iftg-label {
  background-color: #ffea00;
  position: absolute;
  top: -12px;
  right: -12px;
  padding: 12px 10px;
  border-radius: 8px;
  p {
    font-size: 16px;
    margin: 0px;
  }
}

.TDCS {
  height: 20px;
}

.stripeLogo {
  margin-top: 4px;
  height: 18px;
}
