.service-with-container {
  margin-right: 12px !important;
  margin-left: 12px !important;
  width: 100% !important;
  max-width: calc(100% - 18px) !important;
}

.title-component {
  font-weight: 700;
  font-size: 32px;
}

.service-incidence-overflow-container {
  display: flex;
  flex-flow: row;
  margin-top: 12px;
  overflow-x: scroll;
  width: 100%;
  min-height: 100%;
}

.service-incidence-break-container {
  width: auto;
  display: flex;
  flex-flow: row;
}

.vertical-incidence-container {
  display: flex;
  flex-flow: column;
  width: 380px;
  margin: 12px;
}
