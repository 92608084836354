.landing-logo {
  height: 48px;
  margin: 24px 0px 24px 0px !important;
}

.landing-container {
  h1 {
    text-align: center;
  }
}

.checbox-input-container {
  margin-bottom: 24px;
  label {
    font-weight: 500;
    margin: 4px 0px;
    height: 24px;
    cursor: pointer;
  }
  .checbox-input-container-midle {
    display: flex;
    flex-flow: row;
  }
  .topping {
    display: flex;
    margin: 0px 6px;
  }
}

.buttons-container {
  background-color: transparent;
}
