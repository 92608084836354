.oder-anulation-reason {
  text-align: center;
  background-color: rgba(255, 0, 0, 0.25);
  border: 2px solid rgba(255, 0, 0, 0.5);
  padding: 12px;
  border-radius: 12px;
  margin: 24px 0px !important;
}

.order-card-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-membership-name-info {
  margin-top: 12px;
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  width: 100%;
  padding: 12px 0px;
  p {
    margin: 0px;
  }
}

.card-membership-name-info-logo-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.membership-document-link {
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  padding: 6px;
  margin: 12px 12px;
  height: 50px;
  text-align: center;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-decoration: none !important;
  h6 {
    font-size: 12px;
    margin: 0px;
    color: black;
  }
  transition: all 300ms;
}

.membership-document-link:hover {
  background-color: rgba(198, 198, 198, 0.25);
  cursor: pointer;
}

.membership-document-link-container {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: center;
  margin: 0px 0px 12px 0px;
  padding: 0px !important;
}

.renovations-counter-container {
  margin: 12px 0px 48px 0px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
}

.ref-image-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  a {
    text-decoration: none;
  }
  h6 {
    margin-top: 24px;
    font-size: 18px;
  }
}

.center-component {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.document-image-component {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 4px gray;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 10;

  .document-image-component-header {
    height: 48px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px gray;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    z-index: 12;
    top: 0px;
    left: 0px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      flex-flow: row;
      // margin: 0px 12px;
    }

    img {
      height: 24px;
      width: 24px;
      margin: 0px 12px;
    }

    img:hover {
      cursor: pointer;
    }
  }
  overflow: scroll;

  img {
    width: 100% !important;
    z-index: 11;
  }
}

.change-flow-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
}

.image-container-iframe {
  width: 100%;
  height: 100%;
  img {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}
