@import '../../../variables/styles.scss';

.header-component {
  padding: 12px 12px;
  margin-bottom: 12px;
  background-color: $header-background-color;
}

.header-logo {
  width: 200px;
  margin-right: 24px;
  cursor: pointer;
}

.header-links-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.navbar-options-container {
  display: flex;
  flex-flow: row;
  a {
    text-decoration: none;
  }
}

.link-component {
  // width: 150px;
  padding: 12px;
  border-radius: 16px;
  margin: 0px 8px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  transition: all 300ms;
  position: relative;
  cursor: pointer;
  span {
    color: black;
  }
  a,
  a:hover {
    color: black;
  }
  font-size: 14px;
}

.link-component-logo {
  height: 18px;
  width: 18px;
  margin-right: 4px;
}

.link-component:hover {
  background-color: $header-background-color;
  // transform: scale(0.95, 0.95);
}

.link-component-true {
  background-color: $link-component;
}

.header-sublevel-container {
  position: absolute;
  top: 0px;
  width: 90%;
  height: 100%;
  z-index: 999999999999999;
}

.header-sublevel-section {
  position: absolute;
  top: 50px;
  background-color: $header-background-color;
  width: 150px;
  border-radius: 4px;
  box-shadow: 0px 3px 3px #dedfe0;
  z-index: 999999999999999;
}
.header-sublevel-option {
  padding: 6px 12px;
  border-top: 1px solid #e2e2e2;
  transition: all 300ms;
}

.header-sublevel-option:hover {
  margin-left: 4px;
}

.link-component-arrow {
  margin: 0px 12px;
}

.header-name-settins-container {
  display: flex;
  align-items: center;

  h6 {
    margin: 0px;
  }

  img {
    margin: 0px 18px;
    cursor: pointer;
    transition: all 300ms;
  }
}
