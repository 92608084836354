@import '../../../variables/styles.scss';

.login-form {
  margin-top: 54px;
  width: 600px !important;
  padding: 48px 24px;
  border-radius: 4px;
  border-radius: 0.625rem;
  background-color: #ffffff;
  box-shadow: 0 0.15rem 1.75rem 0 $card-box-shadow-color !important;
}

.login-page-container {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;
}

.login-logo-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 24px 0px;

  img {
    height: 48px;
  }
  h6 {
    margin-top: 12px;
    text-align: center;
  }
}

.horizontal-bar-container {
  margin: 24px 0px 12px 0px;
  width: 100%;
  h6 {
    color: $button-border-color;
    font-weight: bold;
    font-size: 16px;
  }
  div {
    height: 3px;
    border-radius: 4px;
    width: 100%;
    margin: 12px 0px 12px 0px;
    background-color: $button-border-color;
  }
}

.register-call-to-action-container {
  margin: 24px 0px 24px 0px;
  text-align: center;
  width: 100% !important;
  font-size: 16px;
}
