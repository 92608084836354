.document-image-component {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 4px gray;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 10;

  .document-image-component-header {
    height: 48px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px gray;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    z-index: 12;
    top: 0px;
    left: 0px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      flex-flow: row;
      // margin: 0px 12px;
    }

    img {
      height: 24px;
      width: 24px;
      margin: 0px 12px;
    }

    img:hover {
      cursor: pointer;
    }
  }

  img {
    width: 100% !important;
    z-index: 11;
  }

  .document-image-component-content {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    height: 100%;
    overflow-y: scroll;
    padding: 0px 12px;
  }
}

.document-image-crane-button {
  box-shadow: 0px 0px 2px gray;
  border-radius: 8px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 12px 6px;
  background-color: #ffffff;
  z-index: 10;
  img {
    height: 36px;
    margin: 0px 6px;
    cursor: pointer !important;
    transition: all 300ms;
  }
}

.document-image-crane-button img:hover {
  transform: scale(1.2, 1.2);
}
