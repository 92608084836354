.benefits-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

.free-plan-into-months {
  img {
    width: 100%;
    // height: 100%;
  }
}

.free-plan-into-months-img-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.benefits-component {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
  border-radius: 48px;
  width: 260px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 6px;
  margin: 12px 0px;
  img {
    margin: 0px 2px;
  }
  h6 {
    text-align: left;
    margin-left: 4px;
    font-weight: 14px;
    width: auto;
  }
}

.benefits-optain-plan {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  p {
    margin-top: 24px;
    font-size: 12px;
  }
}

@media (max-width: 650px) {
  .benefits-container {
    justify-content: center;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 700px) {
  .free-plan-into-months img {
    margin: 12px 0px 48px 0px;
  }
}
