.table-container {
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 66vh; /* Ajusta esto según sea necesario */
}

.missing-properties {
  background-color: #ff6666; // Cambia esto al color que desees
}

.table {
  background-color: white;
  box-shadow: 1px 1px 6px #d3d4d5;
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 12px 6px;
    text-align: center; /* Alineación horizontal */
    vertical-align: middle; /* Alineación vertical */
  }

  th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    padding: 12px 6px;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); /* Añade una sombra para mayor claridad */
  }

  td {
    padding: 8px 6px;
  }

  tr:nth-child(even) {
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
  }
}

.table > :not(caption) > * > * {
  background-color: #fff;
}

.pagination-container {
  margin: 24px 0px 12px 0px;
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.table-buttons-conatiner {
  display: flex;
  flex-flow: row;
  width: fit-content;
}

.incidence-table-section-container {
  padding: 24px 0px;
  h5 {
    margin: 12px 0px;
  }
}

.incidence-table {
  background-color: transparent !important;
  box-shadow: none !important;
}

.providers-detail-table {
  width: 500px;
}

.table-not-available-services {
  width: 100%;
  text-align: center;
}

.exportable-table {
  th {
    min-width: 150px;
    font-size: 14px;
    padding: 6px;
  }
  td {
    padding: 6px;
    font-size: 12px;
    border: 1px solid #d1d1d2;
  }
}

.payments-table {
  th {
    min-width: 100px;
    font-size: 14px;
    padding: 6px;
  }
  td {
    padding: 6px;
    font-size: 12px;
    border: 1px solid #d1d1d2;
  }
}

.sales-afiliation-table {
  th {
    font-size: 14px;
    text-align: center;
    min-width: 100px;
  }

  td {
    font-size: 12px;
    text-align: center;
  }
}

.prices-table {
  th,
  td {
    padding: 12px 0px;
    text-align: center;
  }

  .precentage {
    text-align: center;
    border-right: 1px solid #ededed;
  }

  .precentage-up {
    border-bottom: 1px solid transparent;
  }

  .fila-par {
    background-color: #c5c5c5;
  }
  margin-bottom: 48px !important;
}

.vehicles-table {
  th {
    min-width: 80px;
    font-size: 14px;
    text-align: center;
  }
  td {
    text-align: center;
    font-size: 12px;
  }
}

.table-renewal-container {
  display: flex;
  flex-flow: row;
  margin-top: 12px;
  overflow-x: scroll;
  width: 100%;
  transform: rotateX(180deg);
  margin: 0px;
  margin-top: 12px;
}

.table-renewal-content {
  width: auto;
  display: flex;
  flex-flow: row;
  transform: rotateX(180deg);
}

.table-renewal-container::-webkit-scrollbar {
  height: 8px;
}

.table-renewal-container::-webkit-scrollbar-thumb {
  background-color: #f27730;
  border-radius: 400px;
}

.table-renewal-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.table-container::-webkit-scrollbar {
  height: 8px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #f27730;
  border-radius: 400px;
}

.table-container::-webkit-scrollbar-track {
  background-color: transparent;
}
