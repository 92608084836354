.landing-form-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  form {
    max-width: 700px;
  }
}

.safePayment {
  height: 80px;
  margin-top: 48px !important;
}
