.title-center-component {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.incidence-search-container {
  input {
    border: 1px solid #cacaca;
    background-color: transparent;
    width: 100% !important;
    height: 35px;
    padding: 14px !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  p {
    color: #919191;
    margin: 6px 0px 36px 0px;
    font-size: 12px;
  }
}

.service-avaliable-options-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.service-button-container {
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-width: 250px !important;
  img {
    height: 60px;
    width: fit-content;
    cursor: pointer;
    transition: all 300ms;
  }

  img:hover {
    opacity: 0.5;
  }
}

.selected-service-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 24px;
}

.sms-button-logo-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.sms-button-logo {
  height: 60px;
  transition: all 300ms;
  cursor: pointer;
}

.sms-button-logo:hover {
  opacity: 0.5;
}
