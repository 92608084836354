@import "../../../variables/styles.scss";



// .rcv-holder-form {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }

// .vehicle-owner-question {
//   text-align: center;
//   margin-bottom: 20px;
// }

// .center-label {
//   display: block;
//   margin-bottom: 10px;
// }

// .checkbox-group {
//   display: flex;
//   justify-content: center;
//   gap: 20px;
// }

// .form-row {
//   display: flex;
//   justify-content: center;
//   gap: 20px;
//   margin-bottom: 20px;
// }

// .form-row .col-12 {
//   flex: 1;
// }

.landing-form-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  form {
    max-width: 700px;
  }
}

.landing-form-container-update {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.responsive-landing-form-update {
  width: 100%;
}

.responsive-landing-form-rcv {
  margin: 0 auto;
}

.safePayment {
  width: 400px;
  margin-top: 48px !important;
}

.responsive-landing-form {
  min-width: 700px;
}

@media (max-width: 700px) {
  .responsive-landing-form {
    min-width: auto !important;
  }
}
