.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-content {
  background-color: #f7f8f9;
  min-height: 100vh;
}

.title-component {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 30px;
}

.sub-title-component {
  font-weight: 700;
  margin-bottom: 12px;
  font-size: 24px;
}

.card {
  padding: 8px 10px;
  box-shadow: 1px 1px 6px #d3d4d5;
  margin-top: 12px;
  margin-bottom: 12px;
}

.card-row-container {
  margin: 0px 0px !important;
  .col-12,
  .col-10 {
    padding: 0px !important;
  }
}

.facturacion-component-header {
  margin-bottom: 12px;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;
  div {
    margin-bottom: 12px;
    display: flex;
    flex-flow: row;
    align-items: center;
    img {
      margin: 12px;
    }
  }
}

.facturacion-component-container {
  display: flex;
  flex-flow: row;
}

.facturacion-component-total-expense {
  width: 30%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  img {
    height: 120px;
  }
  h2,
  h4 {
    margin-bottom: 0px;
  }
}

.facturacion-component-cities-data {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.client-facturation-dot {
  height: 10px;
  width: 10px;
  background-color: #f27730;
  border-radius: 10000px;
  margin: 0px 12px;
}

.client-facturacion-component {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 12px 6px;
  h6 {
    text-transform: uppercase;
    margin-bottom: 0px;
  }
}

.indicator-component {
  display: flex;
  flex-flow: row !important;
  align-items: center;
  justify-content: flex-start;
  padding: 24px !important;
  height: 120px !important;
  width: 32%;
  img {
    width: 48px;
  }
  cursor: pointer;
  transition: all 300ms;
}

.indicator-component:hover {
  transform: scale(1.05, 1.05);
}

.indicator-component-image-container {
  width: 20%;
}

.indicator-component-title-container {
  width: 80%;
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0px;
  }
}

.indicators-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
}

.date-pickers-container {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.date-pickers-component {
  display: flex;
  flex-flow: column;
  width: fit-content;
  margin-right: 24px;

  label {
    cursor: pointer;
    font-weight: 500;
  }

  input {
    border: 1px solid #cacaca;
    color: #cacaca;
    border-radius: 8px;
    padding: 4px 12px;
    cursor: pointer;
  }
}

.link-look-alike {
  color: blue;
  text-decoration: underline;
  transition: all 300ms;
}

.link-look-alike:hover {
  cursor: pointer;
}

@media (max-width: 1000px) {
  .indicator-component {
    width: 48%;
  }
}

@media (max-width: 720px) {
  .indicator-component {
    width: 100%;
  }
}
