.landing-form-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  form {
    max-width: 700px;
  }
}

.safePayment {
  width: 400px;
  margin-top: 48px !important;
}

.responsive-landing-form {
  min-width: 700px;
}

@media (max-width: 700px) {
  .responsive-landing-form {
    min-width: auto !important;
  }
}
