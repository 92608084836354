.landing-payment-modal-body {
  width: 100%;
  background-color: #efefef;
  padding: 24px 16px;
  border-radius: 12px;
  p {
    text-align: center !important;
    font-size: 14px;
    margin-bottom: 0px;
  }

  .landing-payment-modal-title {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    padding: 4px;
    border-bottom: 2px solid #d5763a;
    margin-bottom: 12px;
  }
}

.landing-payment-modal-header {
  display: flex;
  flex-flow: row;
  width: 100%;

  .ammount-container {
    width: 50%;
    h5 {
      margin: 0px;
      font-size: 16px;
      color: #212529;
    }
    h6 {
      font-size: 36px !important;
    }
  }
}

.igtf-message-container {
  background-color: #ffea00;
  border-radius: 12px;
  padding: 12px 6px;
}

.payment-method-logos-container {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  img {
    max-height: 40px;
    margin: 0px 12px;
  }
}

.landing-payment-modals-form {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 12px 0px;
}

.wizzard-row {
  background-color: transparent;
}
