.exportables-buttons-container {
  display: flex;
  flex-flow: row;
}

.breadcrumb {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.breadcrumb-export-container {
  position: absolute;
  top: 14%;
  right: 1.4%;
  width: 10%;
  padding: 0;
  background-color: #ffffff;
  z-index: 100;
  border: 1px solid #67606039;
  cursor: pointer;
}

//media queries for les than 1024x900px screen size
@media (max-height: 800px) {
  .breadcrumb-export-container {
    top: 18%;
    right: 1.4%;
    width: 15%;
  }
}
