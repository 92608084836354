.new-payment-amount-component {
  padding: 12px;
  border: 2px solid rgb(242, 120, 49);
  background-color: rgba(242, 120, 49, 0.4);
  border-radius: 8px;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;
  div {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
  }
}
